import { computed, SetupContext } from "@vue/composition-api";

export default function useCompanyMode({ root }: Partial<SetupContext>) {
  const isCompanyModeEnabled = computed(() => root?.$store.getters['companyMode/isCompanyModeEnabled'])
  const getCompanyId = computed(() => root?.$store.getters['companyMode/getCompanyId'])
  const getCompanyData = computed(() => root?.$store.getters['companyMode/getCompanyData'])

  const setCompany = (id: string) => root?.$store.dispatch('companyMode/setCompany', id)
  const removeCompany = () => root?.$store.dispatch('companyMode/removeCompany')

  return { isCompanyModeEnabled, getCompanyId, getCompanyData, setCompany, removeCompany }
}
